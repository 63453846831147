import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'gatsby'
import './SlideMenu.css'
import { Button, Nav, NavDropdown } from "react-bootstrap"
import styled from 'styled-components'
import DonateButton from '../DonateButton/DonateButton'
import ShopButton from '../DonateButton/ShopButton'
import JoinOurTeamModalButton from '../JoinOurTeam/JoinOurTeamModalButton'
import * as styles from "./header.module.css"

const MenuItem = styled.a`
    .bm-item > div > a {
        color: white;
    }
`

// const toggleDropdown = e => {
//     var elem = document.getElementById("history-dropdown").nextElementSibling
//       .classList
//     elem.contains("show") ? elem.remove("show") : elem.add("show")
//   }

//   const toggleConstructionDropdown = e => {
//     var elem = document.getElementById("construction-dropdown").nextElementSibling
//       .classList
//     elem.contains("show") ? elem.remove("show") : elem.add("show")
//   }

//   const toggleSupportersDropdown = e => {
//     var elem = document.getElementById("supporters-dropdown").nextElementSibling
//       .classList
//     elem.contains("show") ? elem.remove("show") : elem.add("show")
//   }

class SlideMenu extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }
    closeMenu () {
        this.setState({menuOpen: false})
    }
    toggleMenu () {
        this.setState(state => ({menuOpen: !state.menuOpen}))
    }
    showSettings (event) {
        event.preventDefault();
    }
    
    render () {
        return (
          <div>

        <div>
            <Menu right width={ '80%' }
                isOpen={this.state.menuOpen}
                onStateChange={(state) => this.handleStateChange(state)}
            >
                <Nav
            className="mr-auto justify-content-center slideDropdown"
            style={{
              width: "100%",
              flexWrap: 'wrap'
            }}
          >
            <div className="dropdown nav-item nav-link">
             <Link
                onClick={() => this.closeMenu()}
                className="home-link"
                eventKey="1.1"
                to="/"
              >
                Home
              </Link>
              </div>
            <NavDropdown title="About Us" id="about-dropdown" alignRight >
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="1.1"
                to="/about-us"
              >
                Vision &amp; Mission
              </Link>
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="1.2"
                to="/about-us#advisory-board"
              >
                Advisory Board
              </Link>
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="1.2"
                to="/about-us#commissioning-committee"
              >
                Commissioning Committee
              </Link>
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="1.3"
                to="/about-us#endorsements"
              >
                Endorsements
              </Link>
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="1.4"
                to="/about-us#committee-crest"
              >
                Committee Crest
              </Link>
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="1.4"
                to="/about-us#contact-us"
              >
                Contact Us
              </Link>
            </NavDropdown>

            <NavDropdown
              title="PCU IDAHO Submarine"
              id="submarine-dropdown"
              alignRight
            >
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="2.1"
                to="/pcu-idaho-submarine#leadership"
              >
                Leadership
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="2.2"
                to="/pcu-idaho-submarine#sponsor"
              >
                Sponsor
              </Link>
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="2.3"
                to="/pcu-idaho-submarine#crew"
              >
                Crew
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="2.4"
                to="/pcu-idaho-submarine#ships-crest"
              >
                Crest
              </Link>
              <NavDropdown.Divider />
              <NavDropdown.Header eventKey="3.4">THE BOAT</NavDropdown.Header>
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="3.4"
                to="/pcu-idaho-submarine#boat-capabilities"
              >
                Capabilities
              </Link>
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="3.4"
                to="/pcu-idaho-submarine#boat-mission"
              >
                Missions
              </Link>
            </NavDropdown>

            <NavDropdown
              title="Construction &amp; Commissioning"
              id="construction-dropdown"
              alignRight
            >
              <Link
                onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="4.1"
                to="/construction-commissioning"
              >
                Construction Phases
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="4.2"
                to="/construction-commissioning#keel-laying"
              >
                Keel Laying
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="4.3"
                to="/construction-commissioning#christening"
              >
                Christening
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="4.4"
                to="/construction-commissioning#commissioning"
              >
                Commissioning
              </Link>
              {/* <Link
              onClick={() => {
                toggleConstructionDropdown()
              }
              }
                className="dropdown-item"
                eventKey="4.4"
                to="/construction-commissioning#request-tickets"
              >
                Request Tickets
              </Link> */}
            </NavDropdown>

            <NavDropdown
              title="History"
              alignRight
              collapseOnSelect
              id="history-dropdown"
              className="top-level-dropdown"
            >
              <Link
                onClick={() => this.closeMenu()}
                to="/history#uss-idaho-ships"
                eventKey="5.1"
                className="dropdown-item nav-link slidedropdownitem slideDropdownItem"
              >
                USS IDAHO Ships
              </Link>
              <Link
             onClick={() => this.closeMenu()}
                eventKey="5.2"
                className="dropdown-item nav-link slidedropdownitem"
                to="/history#idaho-naval-history"
              >
                Idaho Naval History
              </Link>

              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.3"
                to="/history#idaho-national-guard"
              >
                Idaho National Guard
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.4"
                to="/history#idaho-army-history"
              >
                Idaho Army History
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.5"
                to="/history#idaho-air-force-history"
              >
                Idaho Air Force History
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.5"
                to="/history#idaho-marine-corps-history"
              >
                Idaho Marine Corps History
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.6"
                to="/history#idaho-submariners-on-eternal-patrol"
              >
                Idaho Submariners on Eternal Patrol
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.7"
                to="/history#idaho-congressional-medal-of-honor-recipients"
              >
                Idaho Congressional Medal of Honor Recipients
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.7"
                to="/history#us-navy-ships-named-for-idaho"
              >
                US Navy Ships Named for Idaho Cities, Counties, and Icons
              </Link>
              {/* <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.8"
                to="/history#native-american-warriors"
              >
                Native American Warriors
              </Link> */}
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.9"
                to="/history#submarine-documentaries-books"
              >
                Submarine Documentaries &amp; Books
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.10"
                to="/history#idaho-military-history-documentaries-books"
              >
                Idaho Military History Documentaries &amp; Books
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item nav-link slidedropdownitem"
                eventKey="5.11"
                to="/history#about-the-historian"
              >
                About the Historian
              </Link>
            </NavDropdown>

            <NavDropdown title="Supporters" id="supporters-dropdown" alignRight>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="6.1"
                
                to="/supporters/corporate"
              >
                Corporate
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="6.2"
                
                to="/supporters/small-business-civic"
              >
                Business/Civic Groups
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="6.3"
                
                to="/supporters/individuals"
              >
                Individuals
              </Link>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="6.3"

                to="/supporters/partners"
              >
                Partners
              </Link>
            </NavDropdown>

            
          <NavDropdown title="News" id="news-dropdown" alignRight>
          <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.0"
              to="/upcoming-events"
            >
              Upcoming Events
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.1"
              to="/2025-golf-tournament"
            >
              - 2025 Golf Tournament
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.1"
              to="/upcoming-events#commissioning-information"
            >
              - Commissioning Information
            </Link>

            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.2"
              to="/events"
            >
              Past Events
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/idaho-799-flood-up-timelapse-video"
            >
              - IDAHO 799 Float Off (Footage)
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/idaho-799-roll-out-timelapse-video"
            >
              - IDAHO 799 Roll Out (Footage)
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/sun-valley-culinary-institute-culinary-training"
            >
              - Sun Valley Culinary Institute - Culinary Training
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.1"
              to="/events"
            >
              - Christening Activities
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/native-american-blessing-of-pcu-idaho"
              
            >
              Native American Blessing
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/submarine-veterans-lunch-with-the-pcu-idaho-crew"
              
            >
              Submarine Veterans Lunch with PCU IDAHO
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/pre-christening-reception-at-subforce-museum"
              
            >
              Submarine Force Christening Reception
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/pcu-idaho-christening-march-16-2024"
              
            >
              Christening Ceremony
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/watch-the-replay-of-the-future-uss-idaho-christening"
              
            >
              Idaho Public TV Christening Ceremony Video Recording
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/guests-enjoy-spud-juice-at-outer-light-brewing-post-christening-celebration"
              
            >
              Electric Boat Post-Christening Reception
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/post-christening-reception-at-electric-boat-provides-warmth-and-comfort"
              
            >
              Outer Light Celebration
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/christening-evening-reception"
              
            >
              Committee Post-Christening Evening Reception
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/idaho-public-televisions-idahos-nuclear-navy-boise-premier-march-11-2024"
            >
              - Boise Premier - Idaho Public TV "Idaho's Nuclear Navy"
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/idaho-public-television-idaho-experience-idahos-nuclear-navy"
            >
              - Idaho Falls Premier - Idaho Public TV "Idaho's Nuclear Navy"
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.2"
              to="/news"
            >
              News Stories &amp; Articles
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.3"
              to="/newsletters"
            >
              Newsletters
            </Link>
            <Link
              onClick={() => this.closeMenu()}
              className="dropdown-item"
              eventKey="7.3"
              to="/school-of-the-boat"
            >
              School Of The Boat
            </Link>
            </NavDropdown>

            <NavDropdown title="Store" id="store-dropdown" alignRight>
              <Link
              onClick={() => this.closeMenu()}
                className="dropdown-item"
                eventKey="8.1"
                to="/products"
              >
                Ship's Merchandise
              </Link>
            </NavDropdown>
            
          </Nav>
          <div >
          <div className="mb-2 text-center">
            <DonateButton btnLink="/events" btnText="Christening Info" />
            </div>
        <div className="mb-2 text-center">
            <DonateButton btnLink="/events/idaho-public-television-idaho-experience-idahos-nuclear-navy" btnText="Idaho Public TV Documentary" />
            </div>
            <div className="mb-2">
            <DonateButton btnLink="/donate" btnText="Donate" />
            </div>
            <div className="mb-2">
              <JoinOurTeamModalButton btnTitle="Join Our Team"/>
            </div>
            <div className="mb-2">
            <DonateButton btnLink="/about-us#contact-us" btnText="Contact" />
            </div>
            <div className="mb-2">
            <ShopButton btnLink="/products" btnText="Shop" />
            </div>
            <div><p style={{
                color: 'white',
                marginLeft: '20px'
            }}>Be a part of history...</p></div>
        </div>
            </Menu>
            </div>
            </div>
        )
    }
}

export default SlideMenu;